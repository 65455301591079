<template>
<!-- 智慧化健身房指数排名 -->
    <div class="fitness darkBack">
        <div class="fitnessnav">
            <div>运动人群时间活跃度</div>
            <div>
                <!-- <span class="selecte" id="selected" @click="selectedhandle">月</span> -->
                <span class="selecte" @click="selectedhandle">日</span>
                <span class="selecte" @click="selectedhandle">周</span>
            </div>
        </div>
        <div id="week2" style="width:5.70rem; height:3.20rem;"></div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            sport:{
                data: ['6', '8', '10', '12', '14', '16', '18', '20', '22', '24'],
                number:[980, 1130, 1720, 2130, 2550, 2670, 3420, 2870, 2310, 1420],
            },
        }
    },
    methods: {
        selectedhandle(e){          // 点击切换样式
            // $('.selecte').class('background', "#000000")
            $('.selecte').css({'background-color':'#00000000', 'color':'#838181'})
            e.target.style.background = "#7EA7E2";
            e.target.style.color = "#000000";
            // 切换柱状图表
            // var switchover = e.target.innerText
            this.getActive(e.target.innerText);
        },
        // 柱状图
        fitness(id, number){
            // let canye = this.$echarts.init(document.getElementById(id));
            let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById(id));
            }
            // var data = number.number
            // var data = [];
            // for (let i = 0; i < 17; ++i) {
            //     data.push(Math.round(Math.random() * 200));
            // }

            let option = {
                xAxis: {
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    axisLabel: {               // x轴字体的颜色
                        textStyle: {
                            show:true,
                            fontSize: '0.13rem',
                        },
                    },
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: '#2D94D0',
                        }
                    },
                    data: number.data
                },
                grid:{
                    left:"8%",
                    right:"8%",
                    top:"15%",
                    bottom: "10%"
                },
                yAxis: {
                    type: 'value',
                    splitNumber: 4,         // y轴左侧显示的条数
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: ['#2D94D040'],
                            width: 1,
                            type: 'solid',
                        }
                　　},
                    axisLabel: {        // 隐藏y轴数据
                        formatter: function () {
                            return "";
                        }
                    },
                },
                series: [{
                    data: number.number,
                    symbol: 'circle',//拐点样式
                    symbolSize: 9,   //拐点圆的大小
                    type: 'line',
                    itemStyle : { 
                        normal : { 
                            color:'#3AE6CE', //改变折线点的颜色
                            lineStyle:{ 
                                color:'#3AE6CE' //改变折线颜色
                            } 
                        } 
                    }, 
                }]
            };
                canye.setOption(option);
            // }
            //图表自动缩放
            window.addEventListener("resize",function(){
	            canye.resize();
            });
        },
        // 获取活跃度
        getActive(type){
            let that = this;
            let data,url,result
            data = {
                businessid:window.jianshenfangData.id
            }
            that.sport = {
                data:[],
                number:[]
            }
            if(type == '日'){
                url = window.appHost+`/api/v1/screen/Venue/active_time?businessid=${window.jianshenfangData.id}`
                result = window.ajaxRequest(url,JSON.stringify(data),"post")
                // console.log(result);
                
                for(let i in result.data){
                    that.sport.data.push(i);
                    that.sport.number.push(result.data[i]);
                }
            }else{
                url = window.appHost+`/api/v1/screen/Venue/active_week?businessid=${window.jianshenfangData.id}`
                result = window.ajaxRequest(url,JSON.stringify(data),"post")
                // console.log(result);
                result.data.forEach((item,index)=>{
                    that.sport.data.push(item.num);
                    that.sport.number.push(item.week);
                })
            }
            that.fitness("week2", that.sport)
            
        }
    },
    mounted () {
        // this.fitness("week2", this.sport)
        this.getActive("日")
    }
}
</script>

<style scoped>
.fitness{
    width:5.96rem;
    height:3.63rem;
    font-size: 0.18rem;
    color:#fff;
    padding: 0.2rem;
    position: relative;
    
}
.fitnessnav{
    display: flex;
    justify-content: space-between;
}
.fitnessnav>div:nth-child(2){
    display: flex;
    justify-content: space-around;
    width:1.05rem;
    height:0.22rem;
    border:0.01rem solid #838181;
    border-radius: 0.11rem;
}

.selecte{
    width:50%;
    text-align: center;
    height:0.22rem;
    line-height: 0.22rem;
    display: inline-block;
    font-size: 0.12rem;
    cursor: pointer;
    color:#838181;
}

.selecte:nth-child(1){
    background-color:#7EA7E2;
    border-radius: 0.11rem 0rem 0rem 0.11rem;
    color:#000000;
}

.selecte:nth-child(2){
    /* background:red; */
    border-radius: 0rem 0.11rem 0.11rem 0rem;
}
</style>